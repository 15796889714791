<template>
  <div id="user-profile">
    <nav
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <profile-header />
    </nav>
    <section id="profile-info">
      <b-row class="justify-content-center">
        <!-- post -->
        <b-col md="8" lg="6" sm="11">
          <pembayaran :data="data" />
        </b-col>
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import ProfileHeader from "@/views/payment/components/Navbar.vue";
import Pembayaran from "./utama/Pembayaran.vue";
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    Pembayaran,
    ProfileHeader,
  },
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    getData() {
      this.loading = true;
      let id = this.$route.params.id;

      this.$store
        .dispatch("e-book/detail", id)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {
    if (!this.user) {
      this.$router.replace({ name: "auth-login" });
    }
    this.getData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
